import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            /*
             * The reason we using return back to our page is because once the user
             * is logged in, we need to redirect them back to our app and then redirect them to shopify
             * but we also need to log them out of auth0
             * */
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
