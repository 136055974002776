import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CircularProgress from "@mui/material/CircularProgress";
import "./app.css";

const App = () => {
    const { loginWithRedirect, logout } = useAuth0();
    const { user } = useAuth0();

    /**
     * @param {string} email
     * @param {string} name
     * @param {string} mutipass
     *
     * @returns {string}
     *
     */
    

    useEffect(() => {
        if (!user) {
         loginWithRedirect()

        }
        if (user) {
            /**
             * Logout out of auth0 and redirect to Rubicon
             */
            logout({ returnTo: "https://rubiconsa.myshopify.com" });
        }
    });

    return (
        <>
            <div className="loading">
                <CircularProgress />
            </div>
        </>
    );
};

export default App;
